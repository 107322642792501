import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCheckoutForm from "./CheckoutForm";
import { Payment } from "@mui/icons-material";
import PaymentService from "@root/services/PaymentService";
import { Box, Grid, Typography } from "@mui/material";
import StripeCardPayment from "@containers/stripe/StripeCard";
import QueryString from "query-string";
import { useDispatch } from "react-redux";
import StripePaymentButton, { ButtonShadowed } from "./StripePaymentButton";
import Currency from "@components/currency";

import { buttons, getDevice } from "./constants";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const PUB_KEY = `${process.env.STRIPE_PUB_KEY}`;
const staticPubKey = `pk_test_51JueRREfkrO1UbmcuplOE72GS1ZcRoQ0ck7exJprytrDkWqRDl3ZnD0Gjlh7WSBX9Hz41Jj85NePB9IJqrxp2O8A00TH9BIxDt`;
const stripePromise = loadStripe(PUB_KEY || staticPubKey);

const PaymentButton = (props) => {
  const { device, applePay, googlePay } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#000",
        color: "#fff",
        alignItems: "center",
        borderRadius: "6px",
        height: "39px",
        padding: "6px",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <img
          src={device === "apple" ? applePay : googlePay}
          style={{ height: 22 }}
          alt="payment-button"
        />
        <Currency value={props.totalAmount} />
      </Typography>
    </Box>
  );
};

export default function Stripe(props) {
  console.log({ props });
  const [message, setMessage] = useState("");
  const [loading, setIsLoading] = useState("");

  const dispatch = useDispatch();
  const device = getDevice();

  const googlePay = buttons?.isGooglePayInActive;
  const applePay = buttons?.isApplePayInActive;

  const queryParams = QueryString.parse(window.location.search);
  const { amountInSmallestUnit = 200, currency = "GBP" } = queryParams;
  const { handlePayment, outlet } = props;

  const options = {
    mode: "payment",
    currency: "usd",
    amount: 1099,
  };

  useEffect(() => handlePayment.startOrder(), []);

  async function handleIntent() {
    try {
      const response = await PaymentService.createStripeIntent({
        amountInSmallestUnit: props.totalPayment,
        currency: outlet?.currencyCode,
        orderId: props.orderId,
      });
      console.log("Payment intent created", { response });
      return { clientSecret: response?.intentId };
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    }
  }

  const handelPaymentConfirmation = () => {
    props
      .confirmOrderStatus()
      .then(handlePayment.onPaymentSuccess)
      .catch(() => {
        handlePayment.onPaymentFail().then((res) => props.goToPreviousPage());
      });
  };

  return (
    <>
      <Typography variant="subTitle4" color="text.primary">
        Please enter card details
      </Typography>

      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "16px",
          paddingTop: "32px",
          borderRadius: "6px",
          marginTop: "16px",
        }}
      >
        <Elements stripe={stripePromise} options={options}>
          <Grid container spacing={5}>
            <Grid xs={12} item>
              <StripeCardPayment
                setMessage={setMessage}
                setIsLoading={setMessage}
                handleIntent={handleIntent}
                message={message}
                handelPaymentConfirmation={handelPaymentConfirmation}
                {...props}
              />
            </Grid>
            <Grid xs={12} item>
              <StripePaymentButton
                setMessage={setMessage}
                setIsLoading={setMessage}
                handleIntent={handleIntent}
                message={message}
                handelPaymentConfirmation={handelPaymentConfirmation}
                {...props}
              >
                <PaymentButton {...{ ...props, device, applePay, googlePay }} />
              </StripePaymentButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="sunText5" color="error">
                {props.message}
              </Typography>
            </Grid>
          </Grid>
        </Elements>
      </Box>
    </>
  );
}
